import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import TitleDatePhoto from '../components/TitleDatePhoto'
import Bio from '../components/Bio'
import Share from '../components/Share'

import { rhythm } from '../utils/typography'
import { themeStyles } from '../utils/theme'
import {default as Helmet, baseURL } from '../components/Helmet'
import Img from "gatsby-image"

class PageTemplate extends React.Component {
  render() {
    const page = get(this.props, 'data.markdownRemark')

    const slug = get(page, 'fields.slug')
    const title = get(page, 'frontmatter.title')
    const date = get(page, 'frontmatter.date')
    const excerpt = get(page, 'excerpt')
    const titleImageResponsive = get(page, 'frontmatter.featuredImage.childImageSharp.fluid')
    const thumbnailImage = get(page, 'frontmatter.featuredImage.childImageSharp.fluid.src')
    const fullThumbnailImageURL = baseURL + thumbnailImage

    return (
      <Layout location={this.props.location}>
        <Seo
          title={title}
          description={excerpt}
        />
        <Helmet
          description={excerpt}
          image={fullThumbnailImageURL}
          title={title}
          type="page"
          path={slug}
        />
        <div css={themeStyles.textPadding}>
          {titleImageResponsive && <TitleDatePhoto title={title} date={date} imageResponsive={titleImageResponsive} />}
          <div dangerouslySetInnerHTML={{ __html: page.html }} />
        </div>
        <Bio />
        <Share slug={slug} title={title} excerpt={excerpt} />
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query Page($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`